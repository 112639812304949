import React from "react";
import "./personcard.scss";
import {Button, Card, Col, Image, Row, Tooltip} from "antd";
import {FiLink2} from "@react-icons/all-files/fi/FiLink2";
import {Link} from "react-router-dom";
import {ProfileProps,} from "../../body/Home/PeopleTab/PeopleTabSlice";
import {TagType} from "../TagType/TagType";
import defaultProfilePic from "../../../../src/assets/images/default_user_image.svg";
import defaultCoverPic from "../../../../src/assets/images/cover-photo-small.jpeg";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {BsChatLeftTextFill} from "react-icons/bs";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../hooks";
import {getUniversityResourceSelector} from "../../../MainScreen/MainScreenSlice";
import topRatedBadgePic from "../../../assets//images/badges/top-rated.svg";


type PersonCardType = {
  setVisible: any;
  createdDate?: [];
  domain?: string;
  id: number;
  posts?: [];
  profile: ProfileProps;
  profileLink: string;
  ratings?: [];
  replyTime: {
    unit: string;
    count: number;
  };
  setProfileId: any;
};

function PersonCard({

  profile,
  id,
  replyTime,
  profileLink,
  setVisible,
  setProfileId,
}: PersonCardType) {
  const showModal = (id: number) => {
    setVisible(true);
    setProfileId(id);

  };
  const {t} = useTranslation();
  const { payload} = useAppSelector(
      getUniversityResourceSelector
  );
  return (
      <Col xxl={6} lg={8} xs={12}>
        <div className="personCard personCardMobile ">
          <Card
            className="inPersonCard inPersoncardMobile"
            cover={
              <div className="cover">
                <div className="coverImageBackroundLayout"/>
                <img
                    className="coverImageBackround coverImageBackroundMobile"
                    src={profile?.banner?.imageUrl || defaultCoverPic}
                    alt={t("empty") || ""}
                    onError={({currentTarget}) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = defaultCoverPic;
                    }}
                />


                <div className="topPanel">
                    <TagType type={profile.type} />

                  <div className="copyLinkButton copyLinkButtonMobile">
                    <CopyToClipboard text={profileLink}>
                      <Tooltip title="Copy">
                        <Button
                          shape="circle"
                          size="small"
                          className="incopyLinkButton"
                          icon={<FiLink2 />}
                          ghost
                        />
                      </Tooltip>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="coverImageForeground">
                  <img
                        src={profile?.smallProfileUrl || defaultProfilePic}
                        alt={profile && profile.firstName}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = defaultProfilePic;
                        }}
                        className="img imgMobile coverImageForegroundUserImage"
                    />

                  <div className="userBadge d-none">
                   <img src={topRatedBadgePic} />
                  </div>
               </div>
             </div>
           }
         >
           <div className="personCardIn personCardInMobile">
             <div className="title">
               {profile && profile?.firstName}
               <span className="userCoutryFlag">
                 <img
                     className=""
                     alt=""
                     src={profile?.country?.flagUrl}
                   />
               </span>
             </div>
             <div className="studiesTitle">
               {profile?.courseInfo?.displayName}
               <h3 className="courseLevelTitle">
                 {profile?.programme}
               </h3>
             </div>

             {/*<div className="studiesCompletedTitle">*/}
             {/*  <Button type="link" size="small">*/}
             {/*    {profile?.profession}*/}
             {/*  </Button>*/}
             {/*</div>*/}
             <div className="languages languagesMobile">
               <p className="languagesTitle">{t("dashboard.personCard.languagesTitle")} </p>
               <div className="languagesIn">
                 {profile &&
                   profile.languages.map((lang) => {
                     return <span>{lang} </span>;
                 })}
               </div>
             </div>

             <div className="hobby hobbyMobile">
               <p className="hobbyTitle"> {t("dashboard.personCard.hobbyText")}</p>
               <div className="hobbyIconArea">
                 {profile &&
                   profile.interests &&
                   profile.interests.map((intereset) => {
                     return (
                       <div className="hobbyIconLayout">
                         <Tooltip placement="bottom" title={intereset.name}>
                           <Image
                             className="hobbyIcon"
                             src={intereset.url}
                             // src="https://univiserstg.blob.core.windows.net/public/hiking_black.svg"
                             preview={false}
                           />
                         </Tooltip>
                       </div>
                     );
                   })}
               </div>
             </div>
             <div className="buddyAvgResponseTime buddyAvgResponseTimeMobile">
             {t("dashboard.personCard.buddyAvgResponseTime")}{" "}
               {replyTime.count + " " + replyTime.unit.toLowerCase()}
             </div>
             <div className="userChatButton userChatButtonMobile">
               {/*<SayHelloPopup profile={profile} replyTime={replyTime} profileLink={profileLink} id={id}/>*/}

               <Button
                 onClick={() => showModal(id)}
                 className="inUserChatButton inUserChatButtonMobile"
                 type="primary"
                 style={{backgroundColor: `${payload?.theme?.themeColor}`,borderColor:`${payload?.theme?.themeColor}`}}
                 icon={<BsChatLeftTextFill />}
               >
             {t("dashboard.personCard.userChatButton")}
               </Button>
               <Link
                 className="viewProfileButton"
                 style={{color: `${payload?.theme?.themeColor}`}}

                 to={{
                   pathname: `/buddy/${id}`,
                 }}
               >
               {t("dashboard.personCard.viewProfileButtonIn")}
               </Link>
             </div>
           </div>
         </Card>
       </div>
     </Col>
 );
}

export default PersonCard;
