export type MessageProps = {
    "message": string
    'buddyID' : number
}


export const sendMessageRequest = async (message : MessageProps) => {
    // let userDetails = JSON.parse(<string>localStorage.getItem("token"));
    console.log("message",message)
    let sessionDetails = JSON.parse(localStorage.getItem('sessionDetails') as string)
    return await fetch(
        `${process.env.REACT_APP_API_URL}/chat/buddy/${message.buddyID}/message`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'uniId' : process.env.REACT_APP_BASE_ID as string,
                'session' : sessionDetails?.sessionId
            },
            body: JSON.stringify({
                message: message.message
            })
        }
    )
        .then((res) => res.json())
};
