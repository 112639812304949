import {configureStore} from '@reduxjs/toolkit'
import userReducer from "./components/Login/LoginSlice";
import logger from 'redux-logger'
import universityResourceReducer from "./MainScreen/MainScreenSlice"
import getUniversityBuddyListReducer from "./components/body/Home/PeopleTab/PeopleTabSlice"
import getBuddyViewDetailsReducer from "./components/body/ViewProfileComponent/ViewProfileSlice"
import registerProspectsReducer from "./components/body/RegisterProspect/RegisterProspectSlice"
import getListofChatBuddyListReducer from "./components/body/Chat/buddyList/BuddyListSlice"
import getChatAreaContentReducer from "./components/body/Chat/ChatArea/ChatAreaSlice"
import sendChatMessageReducer from "./components/body/Chat/ChatArea/SendMessage/SendMessageSlice"
import readChatMessageReducer from "./components/body/Chat/ChatArea/ReadMessage/ReadMessageSlice"
import getAttachmentMessageReducer
    from "./components/body/Chat/Attachments/SendAttachmetns/GetAllAttachmentMessageSlice"
import setFaMessageReducer from "./components/body/Chat/FavMessageSwitch/FavMessageSwitchSlice"
import setSelectedBuddyReducer from "./components/body/Chat/SetSelectBuddySlice"
import SendEmailURLReducer from "./components/body/Home/SharePageEmailPopup/SharePageEmailPopupSlice"
import getAllFavMessagesReducer from "./components/body/Chat/getAllStartMessages/FavMessageSwitchSlice"
import SendOTPReducer from "./components/body/Home/SayHelloPopup/ReSendOTP/ReSendOTPSlice";
import ValidateOTPReducer from "./components/body/Home/SayHelloPopup/ValidateOTP/ValidateOTPSlice";
import BuddyRatingReducer from "./components/body/Rating/AddBuddyRatingSlice";

export const store = configureStore({
    reducer: {
        users: userReducer,
        universityResource: universityResourceReducer,
        getUniversityBuddyList: getUniversityBuddyListReducer,
        getBuddyViewDetails: getBuddyViewDetailsReducer,
        registerProspects:registerProspectsReducer,
        getListofChatBuddyList:getListofChatBuddyListReducer,
        sendChatMessage:sendChatMessageReducer,
        readChatMessage:readChatMessageReducer,
        getAttachmentMessage:getAttachmentMessageReducer,
        chatAreaContent:getChatAreaContentReducer,
        emailURL :SendEmailURLReducer,
        selectedBuddy:setSelectedBuddyReducer,
        setFavMessages:setFaMessageReducer,
        getAllFavMessages:getAllFavMessagesReducer,
        resendOTP: SendOTPReducer,
        validateOTP: ValidateOTPReducer,
        buddyRating: BuddyRatingReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
