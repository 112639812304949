import React, {useEffect, useState} from "react";
import {Button, message, Modal, Spin} from "antd";
import {IoMdCheckmarkCircle} from "@react-icons/all-files/io/IoMdCheckmarkCircle";
import "./MessageSuccessPopup.scss";
import {FaCopy} from "@react-icons/all-files/fa/FaCopy";
import {FaFacebookSquare} from "@react-icons/all-files/fa/FaFacebookSquare";
import {MdEmail} from "react-icons/md";
import {AiFillTwitterCircle} from "react-icons/ai";
import {FacebookShareButton, TwitterShareButton} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import {useSelector} from "react-redux";
import {registerProspectSelector} from "../../RegisterProspect/RegisterProspectSlice";
import {useAppSelector} from "../../../../hooks";
import {getUniversityResourceSelector} from "../../../../MainScreen/MainScreenSlice";
import {useTranslation} from "react-i18next";

const MessageSuccessPopup = ({ isModalVisible, setIsModalVisible }: any) => {
  const [] = useState(true);
  const { status, isFetching, sessionDetails } = useSelector(
      registerProspectSelector
  );
  const {payload} = useAppSelector(
      getUniversityResourceSelector
  );


  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const info = () => {
    message.info('Content copied to clipboard');
  };

  useEffect(()=>{
    console.log("sessionDetails",sessionDetails)
  },[sessionDetails])

  const {t} = useTranslation();
  return (
    <>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Spin spinning={isFetching}>
        <div className="messageSuccessPopupTitle">
          <div className="PopupTitleIconHeaderLayout">
            <div className="PopupTitleIcon">
              <IoMdCheckmarkCircle size={50} />
            </div>
            <div className="titleHeaderLayout">
              <div className="titleHeader">{t("dashboard.MessageSuccessPopup.titleHeader")}</div>
              <div className="secondTitleText">{t("dashboard.MessageSuccessPopup.secondTitleText")} {sessionDetails?.defaultReplyTime}</div>
            </div>
          </div>
          <div className="titleHeaderSub">
          {t("dashboard.MessageSuccessPopup.titleHeaderSub1")} {""} {payload?.receiveEmail} {""} {t("dashboard.MessageSuccessPopup.titleHeaderSub2")} {""} {sessionDetails?.buddyName} {""}
          {t("dashboard.MessageSuccessPopup.titleHeaderSub3")}
          </div>
          <div className="referingToFriendText">
          {t("dashboard.MessageSuccessPopup.referingToFriendText1")} {""} {sessionDetails?.buddyName} {""} {t("dashboard.MessageSuccessPopup.referingToFriendText2")}
          </div>

          <div className="referingToFriendButtonLayout">
            <FacebookShareButton
              className="inReferingToFriendButton"
              url={process.env.REACT_APP_URL as string}
            >
              <FaFacebookSquare className="facebookIcon" size={18} />
              <span className="inReferingToFriendButtonText">Facebook</span>
            </FacebookShareButton>



            <TwitterShareButton
              className="inReferingToFriendButton"
              url={process.env.REACT_APP_URL as string}
            >
              <AiFillTwitterCircle className="twitterIcon" size={18} />
              <span className="inReferingToFriendButtonText">Twitter</span>
            </TwitterShareButton>

            <Button className="inReferingToFriendButton">
              <MdEmail className="emailIcon" size={18} />
              <span className="inReferingToFriendButtonText">Email</span>
            </Button>

            <CopyToClipboard text={process.env.REACT_APP_URL as string} >
              <Button className="inReferingToFriendButton" onClick={info}>
                <FaCopy className="copyUrlIcon" size={16} />
                <span className="inReferingToFriendButtonText">Copy Link</span>
              </Button>
            </CopyToClipboard>

          </div>
        </div>
        </Spin>
      </Modal>
    </>
  );
};

export default MessageSuccessPopup;
