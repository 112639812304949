import {ValidateOTPDTO} from "./ValidateOTPSlice";

export const validateOTPRequest = async (otpDTO: ValidateOTPDTO) => {
    let sessionDetails = JSON.parse(localStorage.getItem('sessionDetails') as string)
    let unitId = process.env.REACT_APP_BASE_ID as string
    return await fetch(
        `${process.env.REACT_APP_API_URL}/chat/prospect/${otpDTO.pid}/verify`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'uniId':unitId,
                'session' : sessionDetails?.sessionId
            },
            body: JSON.stringify({
                "code": otpDTO.code
            }),
        }
    )
        .then((res) => res.text())
};
