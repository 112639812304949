import {Button, Layout, Result} from 'antd';
import React, {useEffect, useState} from "react";
import BuddyList from "./buddyList/BuddyList";
import ChatArea from "./ChatArea/ChatArea";
import HeaderMain from '../../common/HeaderMain/HeaderMain';
import {useNavigate} from "react-router-dom";
import "./Chat.scss"
import {useSelector} from "react-redux";
import {getAllBuddyListSelector} from "./buddyList/BuddyListSlice";
import {isBrowser} from 'react-device-detect';

function Chat() {
    const navigate = useNavigate()
    let sessionDetails = JSON.parse(localStorage.getItem('sessionDetails') as string)
    let {status} = useSelector(getAllBuddyListSelector);
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        if (!sessionDetails) {
            navigate("/")
        }
    }, [])

    const showDrawer = () => {
        setCollapsed(!collapsed);
    };

    const onClose = () => {
        setCollapsed(true);
    };

    return (
        <Layout>
            {status === "FAILED" ? <Result
                    status="500"
                    title="500"
                    subTitle="Sorry, something went wrong."
                    extra={<Button type="primary" onClick={() => navigate(-1)}>Back Home</Button>}
                /> :
                <>
                    <HeaderMain/>
                    <Layout className=''>
                        <div className='buddieListAreaLayout'>
                            {isBrowser &&
                                <>
                                <BuddyList onClose={onClose}/>
                                <div className="buddieChatAreaLayout">
                                <ChatArea backtoBuddy={showDrawer}/>
                                </div>
                                </>
                            }
                            {!collapsed && !isBrowser &&
                                <BuddyList onClose={onClose}/>
                            }
                            {collapsed && !isBrowser &&
                                <div className="buddieChatAreaLayout">
                                    <ChatArea backtoBuddy={showDrawer}/>
                                </div>
                            }
                        </div>

                    </Layout>
                    {/* <FooterMain/> */}
                </>
            }
        </Layout>

    );
}

export default Chat;
