import {RegisterProps} from "../../Models/RegisterProps";

function genRandonString(length:number) {
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
    var charLength = chars.length;
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
}

export const registerUserProspectRequest = async (user: RegisterProps) => {
    try {
        const unitId = process.env.REACT_APP_BASE_ID as string;
        let headers;
        if (!!user.refKey) {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'uniId': unitId,
                'ref-key': user.refKey
            };
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'uniId': unitId
            };
        }

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/chat/buddy/${user.id}/v2/register`,
            {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    message: user.message,
                    email: user.email,
                    phone: user.phone,
                    name: user.name,
                    channels: user.channels,
                }),
            }
        );

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(errorResponse.message);
        }

        return response.json();
    } catch (error:any) {
        throw new Error(error);
    }
};
