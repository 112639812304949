import React, {memo, useEffect, useState} from "react";
import "./YourSelfContainer.scss";
import {Checkbox, Form as AntForm, FormInstance, Input,} from "antd";
import {Content} from "antd/es/layout/layout";
import {Field, Form} from "react-final-form";
import {useTranslation} from "react-i18next";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'

import {
    composeValidators,
    minTextLimit,
    required,
    validateEmail,
    validatePhone
} from "../../../../utils/Validations/Validations";

export type channelTypeProps =
    "EMAIL" | "SMS" | 'WHATSAPP'

export type RegisterProspectProps = {
    "message": string
    "email": string
    "phone": string
    "name": string
    "channels": [channelTypeProps]
}

interface YourSelfContainerProps {
    onNext: (regProps: RegisterProspectProps) => void
    messageContent: string
    initValue:RegisterProspectProps
}

const YourSelfContainer = ({onNext, messageContent,initValue}: YourSelfContainerProps) => {
    const formRef = React.createRef<FormInstance>();
    const [reqRequest, setReqRequest] = useState<RegisterProspectProps>(initValue)
    useEffect(() => {
        console.log("reqRequest", reqRequest)
        setReqRequest({...reqRequest, message: messageContent})
    }, [messageContent])

    useEffect(()=>{
        console.log("reqRequest", reqRequest)

    },[reqRequest])

    const {t} = useTranslation();

    const onFinish = (values: any) => {
        console.log("valuesasd",values);
    };

    const onChange = (checkedValues: CheckboxValueType[]) => {
        console.log('checked = ', checkedValues);
        setReqRequest({...reqRequest, channels: checkedValues as [channelTypeProps]})

    };

    const plainOptions = ["SMS", "WHATSAPP"];
    const CheckboxGroup = Checkbox.Group;

    // @ts-ignore
    return (
        <>

            <div className="addSummeryForm">
                <Form
                    initialValues={reqRequest}
                    onSubmit={() => onNext(reqRequest)}
                    render={({handleSubmit}: any) => (
                        <form
                            className="addSummeryFormIn"
                            onSubmit={handleSubmit}
                            id={"addSummeryForm"}
                        >
                            <Content>
                                <AntForm
                                    ref={formRef}
                                    name="control-ref"
                                    onFinish={onFinish}
                                    layout="vertical"
                                >
                                    <div className="yourName">
                                        <Field name="name"
                                               validate={composeValidators(required, (text: string) => minTextLimit(text, 3))}>

                                            {({input, meta, ...rest}) => (
                                                <AntForm.Item
                                                    className="yourNameText"
                                                    name="name"
                                                    label={t("dashboard.YourSelfContainer.yourName")}

                                                    validateStatus={
                                                        meta.touched && meta.error ? "error" : ""
                                                    }
                                                    help={meta.touched && t(meta.error)}
                                                    rules={[{required: true}]}
                                                >
                                                    <div className="yourNameInput">
                                                        <Input
                                                            className="ant-input yourNameInputIn"
                                                            value={reqRequest?.name}
                                                            placeholder={t("dashboard.YourSelfContainer.yourNameHint") || ""}
                                                            onChange={(e) => setReqRequest({
                                                                ...reqRequest,
                                                                name: e.target.value
                                                            })}
                                                            {...rest}
                                                        />
                                                    </div>
                                                </AntForm.Item>
                                            )}
                                        </Field>
                                    </div>

                                    <div className="yourEmailAddress">
                                            <Field
                                                className="yourEmailAddressIn"
                                                placeholder={t("dashboard.YourSelfContainer.yourEmailAddressHint")}
                                                name="email"
                                                validate={composeValidators(required, (input: string) => validateEmail(input))}>

                                                {({input, meta, ...rest}) => (
                                                    <AntForm.Item
                                                        className="yourEmailAddressText"
                                                        name="email"
                                                        tooltip={t('dashboard.SayHelloPopup.infoButton')}

                                                        label={t("dashboard.YourSelfContainer.yourEmailAddress")}
                                                        validateStatus={
                                                            meta.touched && meta.error ? "error" : ""
                                                        }

                                                        help={meta.touched && t(meta.error)}
                                                        rules={[{required: true}]}
                                                    >
                                                        <div className="yourEmailAddressInput">
                                                            <Input onChange={(e) => setReqRequest({
                                                                ...reqRequest,
                                                                email: e.target.value
                                                            })} {...rest}
                                                                   value={reqRequest?.email}/>
                                                        </div>

                                                    </AntForm.Item>
                                                )}
                                            </Field>
                                    </div>
                                    <Field
                                        name="yourMobileNumber"
                                    >
                                        {({input, meta, ...rest}) => (
                                            <AntForm.Item
                                                noStyle
                                                shouldUpdate={(prevValues, currentValues) =>
                                                    prevValues.gender !== currentValues.gender
                                                }
                                            >
                                                <div className="yourMobileNumber">
                                                    <div>
                                                        <Field
                                                            className="yourMobileNumberIn"
                                                            placeholder={t("dashboard.YourSelfContainer.yourMobileNumberHint")}
                                                            name="phone"
                                                            validate={composeValidators(required, (input: string) => validatePhone(input))}>

                                                            {({input, meta, ...rest}) => (
                                                                <AntForm.Item
                                                                    className="yourMobileNumberText"
                                                                    name="phone"
                                                                    validateStatus={
                                                                        meta.touched && meta.error ? "error" : ""
                                                                    }
                                                                    help={meta.touched && t(meta.error)}
                                                                    label={t("dashboard.YourSelfContainer.yourMobileNumber")}
                                                                    rules={[{required: true}]}
                                                                >
                                                                    <div className="yourMobileNumberInput">
                                                                        <PhoneInput

                                                                            country={'lk'}
                                                                            containerClass="yourEmailAddressIn"
                                                                            placeholder="Enter phone number"
                                                                            value={reqRequest?.phone}
                                                                            onChange={(e :any) => setReqRequest({...reqRequest, phone: e || ""})}
                                                                            {...rest}
                                                                        />
                                                                    </div>
                                                                </AntForm.Item>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </AntForm.Item>
                                        )}
                                    </Field>
                                </AntForm>
                                <div className="notifyCheckBox">
                                    <Field name="channel">
                                        {({input, meta, ...rest}) => (
                                            <AntForm.Item
                                                name="channel"
                                                valuePropName="checked"
                                                validateStatus={
                                                    meta.touched && meta.error ? "error" : ""
                                                }
                                                extra={<span>{t("dashboard.SayHelloPopup.checkOptionsHint")}</span>}
                                                help={meta.touched && meta.error}
                                                label=""
                                            >
                                                <Checkbox defaultChecked disabled >EMAIL</Checkbox>

                                                <CheckboxGroup
                                                    value={reqRequest?.channels}
                                                    options={plainOptions}
                                                    onChange={onChange}>
                                                </CheckboxGroup>

                                            </AntForm.Item>
                                        )}
                                    </Field>
                                </div>

                            </Content>
                        </form>
                    )}
                />
            </div>
        </>
    );
};

export default memo(YourSelfContainer);
