import {Button, Divider, Input, Spin} from "antd";
import React, {memo, useState} from "react";
import "./TypeMessageContainer.scss";
import {BsChatLeftTextFill} from "react-icons/bs";
import {MdLock} from "react-icons/md";
import {RiStarSFill} from "react-icons/ri";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../hooks";
import {getUniversityResourceSelector} from "../../../../MainScreen/MainScreenSlice";

const { TextArea } = Input;
const TypeMessageContainer = ({ messageState, setMessageState }: any) => {
  const { t } = useTranslation();
  const { isFetching, status, payload } = useAppSelector(
    getUniversityResourceSelector
  );
  const [buttonArray,setButtonArray] = useState(payload?.chatSuggestions);

  const onButtonClickSuggestion = (id:number,message:string) => {
    console.log("index",id)
    setButtonArray(payload?.chatSuggestions.filter((item,index) => item.id !=id ))
    setMessageState(message)
  }

  const onChangeTypeMessageContent = (content:string) => {
    setMessageState(content)
    setButtonArray(payload?.chatSuggestions)
  }

  return (
    <>
      <div className="textAreaHeader">
        <div className="messageHeading">
          {t("dashboard.TypeMessageContainer.messageHeading")}
          <div>
            <RiStarSFill className="starIcon" size={8} />
          </div>
        </div>

        <div className="privacyText">
          <MdLock className="secureIcon" size={16} />
          {t("dashboard.TypeMessageContainer.privacyText")}
        </div>
      </div>
      <div className="textArea" style={{ position: "relative" }}>
        <TextArea
          className="textAreaIn"
          placeholder={t("dashboard.TypeMessageContainer.textAreaHintText")|| ""}
          rows={17}
          autoFocus={true}
          value={messageState}
          onChange={(e) => onChangeTypeMessageContent(e.target.value)}
        />

        <Divider className="textAreaDividerIn" />

        <div className="aiButtonHintLayout">
          {t("dashboard.TypeMessageContainer.aiButtonHintText")}
        </div>

        <div className="aiButtonLayoutMain">
          <Spin spinning={isFetching}>
            <div className="aiButtonLayoutRow">
              {buttonArray?.map((suggestion, index) => {
                return (
                  <Button
                    key={index}
                    className="aiButton1 "
                    type="primary"
                    icon={<BsChatLeftTextFill />}
                    style={{
                      color: `${payload?.theme?.themeColor}`,
                      borderColor: `${payload?.theme?.themeColor}`,
                    }}
                    onClick={() => onButtonClickSuggestion(suggestion.id,`${suggestion?.message}`)}
                    ghost
                  >
                    <div
                      className="inUserChatText inUserChatTextMobile"
                      style={{
                        color: `${payload?.theme?.themeColor}`
                       ,
                      }}
                    >
                      {suggestion?.message}
                    </div>
                  </Button>
                );
              })}
            </div>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default memo(TypeMessageContainer);
