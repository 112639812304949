import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { Col, Layout, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { getUniversityResourceSelector } from "../../../../MainScreen/MainScreenSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import PeopleTab from "../PeopleTab/PeopleTab";
import { hexatoRGBColorFunction } from "../../../../utils/hexatoRGBColorFunction";

const { Content } = Layout;

function Dashboard() {
  const [idToken, setIdToken] = useState(null);
  const [enableWordPress, setEnableWordPress] = useState<any>(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const wordPress = searchParams.get("wordpress");

    if (wordPress) {
      setEnableWordPress(wordPress);
    }
  }, []);

  const dispatch = useAppDispatch();
  const { isFetching, status, payload } = useAppSelector(
    getUniversityResourceSelector
  );
  const { t, i18n } = useTranslation();
  const {} = i18n;

  return (
    <div className="home_page">
      <Content>
        <Spin spinning={isFetching}>
          {!enableWordPress ? (
            <div
              className="jumbotron bg-jumb bannerMobile"
              style={{
                background: `linear-gradient(79deg, ${hexatoRGBColorFunction(
                  payload?.theme?.themeColor || "#fafafa"
                )} 0%, ${hexatoRGBColorFunction(
                  payload?.theme?.secondaryColor || "#fafafa"
                )} 100%)`,
              }}
            >
              <div className={"jumb-container jumb-containerMobile"}>
                <h2 className="bannerText">{payload?.theme?.headerMessage}</h2>
                <Row>
                  <Col xs={24} lg={16}>
                    <p className="bannerSubText bannerHeaderSubTextMobile">
                      {payload?.theme?.contentMessage}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/*<div className="bannerHeader bannerHeaderMobile ">*/}
          {/*      {t("dashboard.captionFilter.title")}*/}
          {/*      Start chatting with our...*/}
          {/*  </div>*/}

          <div className="">
            <PeopleTab />
          </div>
        </Spin>
      </Content>
    </div>
  );
}

export default Dashboard;
