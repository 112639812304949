import React from 'react';
import {Route, Routes} from "react-router-dom";
import Dashboard from "./Home/Dashboard/Dashboard";
import ViewProfile from "./ViewProfileComponent/ViewProfile";
import Chat from "./Chat/Chat";

function Body() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Dashboard/>}/>
                <Route path="buddy/:id" element={<ViewProfile/>}/>
                <Route path="chat" element={<Chat/>}/>

            </Routes>
        </>
    );
}

export default Body;
