import React, { useEffect, useState } from "react";
import "./HeaderMain.scss";
import {
  Button,
  Divider,
  Dropdown,
  Layout,
  Menu,
  message,
  Select,
  Space,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { FaHome } from "@react-icons/all-files/fa/FaHome";
import { FaShareAlt } from "@react-icons/all-files/fa/FaShareAlt";
import { FiMenu } from "@react-icons/all-files/fi/FiMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  fetchUniversityResourceById,
  getUniversityResourceSelector,
} from "../../../MainScreen/MainScreenSlice";
import { useDispatch } from "react-redux";
import "../../../MainScreen/MobileMenu";
import { useAppSelector } from "../../../hooks";
import usaFlag from "../../../../src/assets/images/usa.png";
import SharePageEmailPopup from "../../body/Home/SharePageEmailPopup/SharePageEmailPopup";
import MobileMenu from "../../../MainScreen/MobileMenu";
import { BsChatLeftTextFill, BsPeopleFill } from "react-icons/bs";
import {
  clearRegisterProspects,
  registerProspectSelector,
} from "../../body/RegisterProspect/RegisterProspectSlice";
import { MdLogout } from "react-icons/md";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Helmet } from "react-helmet";
// Share icons
import iconFb from "../../../../src/assets/images/header/menu-icon-fb.svg";
import iconTw from "../../../../src/assets/images/header/menu-icon-tw.svg";
import iconCopy from "../../../../src/assets/images/header/menu-icon-copy.svg";

const { Option } = Select;

const { Header } = Layout;

const info = () => {
  message.info("Content copied to clipboard");
};

const menu = (
  <Menu>
    <div>
      <Menu.Item key="0">
        <div>
          <FacebookShareButton
            className="dropDownShareButtonFb"
            url={process.env.REACT_APP_URL as string}
          >
            <img src={iconFb} alt="" />
            {/* <FaFacebookSquare className="facebookIcon"size={18} /> */}
            <div className="dropDownShareButtonText">Facebook</div>
          </FacebookShareButton>
        </div>
      </Menu.Item>

      <Menu.Item key="2">
        <TwitterShareButton
          className="dropDownShareButtonFb"
          url={process.env.REACT_APP_URL as string}
        >
          {/* <AiFillTwitterCircle className="twitterIcon" size={18} /> */}
          <img src={iconTw} alt="" />
          <div className="dropDownShareButtonText">Twitter</div>
        </TwitterShareButton>
      </Menu.Item>

      {/*<Menu.Item key="2">*/}
      {/*  <EmailShareButton*/}
      {/*      url={process.env.REACT_APP_URL as string}*/}
      {/*  >*/}
      {/*    <AiOutlineMail size={18} />*/}
      {/*    <div className="dropDownShareButtonText">Email</div>*/}
      {/*  </EmailShareButton>*/}
      {/*</Menu.Item>*/}
      <Menu.Item key="3">
        <SharePageEmailPopup type={""} />
      </Menu.Item>
      <Menu.Item key="4">
        <CopyToClipboard
          text={process.env.REACT_APP_URL as string}
          onCopy={info}
        >
          <button className="dropDownShareButtonCopyUrl" onClick={() => info}>
            {/* <FaCopy className="copyUrlIcon" size={16} /> */}
            <img src={iconCopy} alt="" />
            <div className="dropDownShareButtonText">Copy Url</div>
          </button>
        </CopyToClipboard>
      </Menu.Item>
    </div>
  </Menu>
);

const menuMobile = <MobileMenu />;

function HeaderMain() {
  const { t } = useTranslation();

  const [showChatButton, setShotChatButton] = useState(false);
  const [showLogoutButton, setShotLogoutButton] = useState(false);
  const [showAllBuddiesButton, setShotAllBuddiesButton] = useState(false);
  const [hideNavButtons, setHideNavButtons] = useState(false);

  // const {id} = useParams();
  let dispatch = useDispatch();
  const { payload } = useAppSelector(getUniversityResourceSelector);
  const { status } = useAppSelector(registerProspectSelector);
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (e: any) => {
    i18next.changeLanguage(e);
  };

  function refreshPage() {
    window.location.reload();
  }

  function logout() {
    dispatch(clearRegisterProspects());
    localStorage.removeItem("sessionDetails");
    navigate("/");
  }

  function getFaviconEl() {
    return document.querySelector(
      "link[rel~='icon']"
    ) as HTMLAnchorElement | null;
  }

  useEffect(() => {}, []);

  useEffect(() => {
    let sessionDetails = JSON.parse(
      localStorage.getItem("sessionDetails") as string
    );

    if (status === "SUCCESS" || !!sessionDetails) {
      setShotChatButton(true);
    }
  }, [status]);

  useEffect(() => {
    if (location?.pathname === "/chat") {
      setShotChatButton(false);
    }

    if (location?.pathname === "/buddyRating") {
      setHideNavButtons(true);
    }
  }, [location]);

  useEffect(() => {
    let sessionDetails = JSON.parse(
      localStorage.getItem("sessionDetails") as string
    );

    if (status === "SUCCESS" || !!sessionDetails) {
      setShotLogoutButton(true);
    }
  }, [status]);

  useEffect(() => {
    let sessionDetails = JSON.parse(
      localStorage.getItem("sessionDetails") as string
    );

    if (status === "SUCCESS" || !!sessionDetails) {
      setShotAllBuddiesButton(false);
    }
  }, [status]);

  useEffect(() => {
    if (location?.pathname === "/chat") {
      setShotAllBuddiesButton(true);
    }
  }, [location]);

  useEffect(() => {
    const favicon = getFaviconEl(); // Accessing favicon element
    if (favicon != null) {
      favicon.href = payload?.theme.faviconUrl!;
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname) {
      // dispatch(fetchUniversityResourceById(window.location.host));
      dispatch(
        fetchUniversityResourceById(process.env.REACT_APP_BASE_ID as string)
      );
    }
  }, []);

  return (
    <div className="headerTopLeftPanel">
      <Header
        className="header headerMobile"
        style={{ justifyContent: "space-between" }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{payload?.theme.shortName}</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <Space>
          <Link to={"/"}>
            <div className="Logo">
              <img
                className="imgLogo"
                src={payload?.theme?.logoUrl || ""}
                alt="logo"
              />
            </div>
          </Link>
        </Space>

        <Space style={{ float: "right" }}>
          <Space style={{ float: "right" }}>
            <div className="headerTopRightPanel  showHeaderTopRightPanel">
              {!hideNavButtons ? (
                <div className="homeNavBar">
                  <Link to="/">
                    <Button
                      className="homeNavBarButton"
                      type="default"
                      icon={<FaHome className="homeIcon" />}
                      size="large"
                      ghost
                    >
                      {t("HeaderMain.homeNavBarButton")}
                    </Button>
                  </Link>
                </div>
              ) : (
                <></>
              )}

              {!hideNavButtons ? (
                <div className="shareNavBar">
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    placement="bottomCenter"
                  >
                    <a
                      href={""}
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <FaShareAlt />
                      {t("HeaderMain.shareNavBarDropdown")}
                      <DownOutlined />
                    </a>
                  </Dropdown>
                </div>
              ) : (
                <></>
              )}
              {/*{*/}
              {/*  //Check if message failed*/}
              {/*  showChatButton ? (*/}
              {/*    <div className="myChats">*/}
              {/*      <Link to="/chat">*/}
              {/*        <Button*/}
              {/*          className="myChatsButton"*/}
              {/*          type="primary"*/}
              {/*          style={{backgroundColor: `${payload?.theme?.themeColor}`,borderColor:`${payload?.theme?.themeColor}`}}*/}

              {/*          icon={<BsChatLeftTextFill style={{ color: "white" }} />}*/}
              {/*          size="large"*/}
              {/*          ghost*/}
              {/*        >*/}
              {/*          {t("HeaderMain.myChatsButton")}*/}
              {/*        </Button>*/}
              {/*      </Link>*/}
              {/*    </div>*/}
              {/*  ) : (*/}
              {/*    ""*/}
              {/*  )*/}
              {/*}*/}
              {showAllBuddiesButton ? (
                <div className="allBuddies">
                  <Link to="/">
                    <Button
                      className="allBuddiesButton"
                      type="primary"
                      style={{
                        backgroundColor: `${payload?.theme?.themeColor}`,
                        borderColor: `${payload?.theme?.themeColor}`,
                      }}
                      icon={<BsPeopleFill style={{ color: "white" }} />}
                      size="large"
                      ghost
                    >
                      {t("HeaderMain.allBuddiesButton")}
                    </Button>
                  </Link>
                </div>
              ) : (
                ""
              )}

              <Divider
                style={{ borderColor: "#dcdfe0", height: "50px" }}
                type="vertical"
              />

              {/*{showLogoutButton ? (*/}
              {/*  <div className="logoutNavBar">*/}
              {/*  <Button*/}
              {/*    className="logoutNavBarButton"*/}
              {/*    type="default"*/}
              {/*    icon={<MdLogout />}*/}
              {/*    onClick={() => {logout(); refreshPage(); }   }*/}
              {/*    size="large"*/}
              {/*    ghost*/}
              {/*  >*/}
              {/*    <div className="logoutNavBarText">{t("HeaderMain.logoutNavBarButton")}</div>*/}
              {/*  </Button>*/}
              {/*</div>*/}
              {/*) : (*/}
              {/*  ""*/}

              {/*)}*/}

              <div className="languge ">
                <div className="userLangugeImg">
                  <img
                    className="inUserLangugeImg"
                    alt="example"
                    src={usaFlag}
                  />
                  <Select
                    className="languageSelect"
                    defaultValue="en"
                    onChange={changeLanguage}
                  >
                    {payload?.univeristyConfig &&
                      payload?.univeristyConfig.languages &&
                      payload?.univeristyConfig.languages.map((lang) => {
                        return (
                          <Option value={lang.toLowerCase()}>{lang}</Option>
                        );
                      })}
                  </Select>
                </div>
              </div>
            </div>
            {location.pathname.includes("chat") && (
              <div className="allBuddiesMobile">
                <Link to="/">
                  <Button
                    className="allBuddiesButtonMobile"
                    type="primary"
                    icon={<BsPeopleFill style={{ color: "white" }} />}
                    style={{
                      backgroundColor: `${payload?.theme?.themeColor}`,
                      borderColor: `${payload?.theme?.themeColor}`,
                    }}
                    size="large"
                    ghost
                  >
                    {t("HeaderMain.allBuddiesButton")}
                  </Button>
                </Link>
              </div>
            )}

            <div className="hideMenuIcon showMenuIcon ">
              {/* {
                //Check if message failed
                showChatButton ? (
                  <div className="myChats">
                    <Link to="/chat">
                      <Button
                        className="myChatsButton"
                        type="primary"
                        icon={<BsChatLeftTextFill style={{ color: "white" }} />}
                        style={{backgroundColor: `${payload?.theme?.themeColor}`,borderColor:`${payload?.theme?.themeColor}`}}
                        size="large"
                        ghost
                      >
                      {t("HeaderMain.myChatsButton")}
                      </Button>
                    </Link>
                  </div>
                ) : (
                  ""
                )
              } */}
              <div className="menuButton">
                <Dropdown
                  className=" "
                  overlay={menuMobile}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Button
                      className="menuButtonIn"
                      type="primary"
                      icon={<FiMenu size={20} style={{ color: "black" }} />}
                      size="large"
                      ghost
                    ></Button>
                    <DownOutlined />
                  </a>
                </Dropdown>
              </div>
            </div>
          </Space>
        </Space>
      </Header>
    </div>
  );
}

export default HeaderMain;
