import React, { Suspense, useEffect, useState } from "react";

import PersonCard from "../../../common/PersonCard/PersonCard";
import "./PeopleTab.scss";
import { FiFilter } from "@react-icons/all-files/fi/FiFilter";
import FilterComponent from "../../../common/FilterComponent/FilterComponent";

import { Button, Col, Empty, Layout, Pagination, Row, Spin, Tabs } from "antd";
import { getUniversityResourceSelector } from "../../../../MainScreen/MainScreenSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../hooks";
import {
  fetchBuddyListByUniversityId,
  getBuddyListSelector,
  PersonProp,
} from "./PeopleTabSlice";
import { useTranslation } from "react-i18next";
import emptyImage from "../../../../assets/images/empty-image.png";
import MessageSuccessPopup from "../SayHelloPopup/MessageSuccessPopup";

const SayHelloPopup = React.lazy(
  () => import("../SayHelloPopup/SayHelloPopup")
);

const { TabPane } = Tabs;
const { Content } = Layout;

export interface FilterOptions {
  page: number | undefined;
  size: number;
  country: string | undefined;
  programme: number | undefined;
  course: number | undefined;
}

function Sonnet({ data, setSayhelloVisible, setProfileId }: any) {
  return (
    <>
      {data.map((item: PersonProp) => {
        return (
          <PersonCard
            {...item}
            setVisible={setSayhelloVisible}
            setProfileId={setProfileId}
          />
        );
      })}
    </>
  );
}

function PeopleTab() {
  const [showfitler, setShowfilter] = useState<boolean>(false);
  const [sayhelloVisible, setSayhelloVisible] = useState<boolean>(false);
  const [isMessageSuccessModalVisible, setIsMessageSuccessModalVisible] =
    useState<boolean>(false);
  const [profileId, setProfileId] = useState<number>(0);
  const [userTermsVisiblity, SetUserTermsVisiblity] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isFetching, status, buddyListPayload } =
    useAppSelector(getBuddyListSelector);
  const [filterData, setFilterData] = useState<PersonProp[]>([]);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    country: undefined,
    course: undefined,
    page: 0,
    programme: undefined,
    size: 12,
  });
  // const {id} = useParams();
  let dispatch = useDispatch();

  const { payload } = useAppSelector(getUniversityResourceSelector);

  useEffect(() => {
    if (payload) {
      SetUserTermsVisiblity(
        (payload?.univeristyConfig &&
          payload?.univeristyConfig.countrySearchEnabled) ||
          (payload?.univeristyConfig &&
            payload?.univeristyConfig.programmeEnabled) ||
          (payload?.univeristyConfig && payload?.univeristyConfig.courseEnabled)
      );
    }
  }, [payload]);

  function filteroutType(type: string = "") {
    return buddyListPayload.items.filter(
      (item) => item.profile.type.toUpperCase() === type
    );
  }

  function callback(tabItem: string) {
    setFilterData(
      filteroutType(payload?.univeristyConfig?.buddyTypes[parseInt(tabItem)])
    );
  }

  useEffect(() => {
    if (status === "success") {
      setFilterData(
        filteroutType(payload?.univeristyConfig?.buddyTypes[parseInt("0")])
      );
    }
  }, [buddyListPayload.items]);

  useEffect(() => {
    if (window.location.pathname) {
      // dispatch(fetchBuddyListByUniversityId({id:window.location.host,filterOptions:filterOptions}));
      dispatch(
        fetchBuddyListByUniversityId({
          id: process.env.REACT_APP_BASE_ID as string,
          filterOptions: filterOptions,
        })
      );
    }
  }, []);

  useEffect(() => {
    dispatch(
      fetchBuddyListByUniversityId({
        id: process.env.REACT_APP_BASE_ID as string,
        filterOptions: filterOptions,
      })
    );
  }, [filterOptions]);

  function onChangePagination(id: number) {
    setFilterOptions({ ...filterOptions, page: id - 1 });
  }

  useEffect(() => {
    console.log("buddyListPayload", buddyListPayload);
  }, [buddyListPayload]);

  const operations = (
    <>
      {userTermsVisiblity ? (
        <Button
          className={!showfitler ? "inFilterButton" : "inFilterButtonClicked"}
          type="default"
          icon={<FiFilter className="filterIcon" size={20} />}
          ghost
          style={{}}
          onClick={() => {
            setShowfilter(!showfitler);
          }}
        >
          {t("dashboard.PeopleTab.inFilterButton")}
        </Button>
      ) : (
        ""
      )}
    </>
  );

  return (
    <div className="peopleTabContent">
      <MessageSuccessPopup
        isModalVisible={isMessageSuccessModalVisible}
        setIsModalVisible={setIsMessageSuccessModalVisible}
      />
      <Suspense fallback={<div>Loading...</div>}>
        {sayhelloVisible && (
          <SayHelloPopup
            buddies={
              buddyListPayload.items.filter((item) => item.id === profileId)[0]
            }
            visible={sayhelloVisible}
            setVisible={(isVisible, isSuccess) => {
              setSayhelloVisible(isVisible);
              setIsMessageSuccessModalVisible(isSuccess);
            }}
            id={profileId}
          />
        )}
      </Suspense>

      <Row>
        <Col xs={18}>
          <div className="bannerHeader bannerHeaderMobile ">
            {t("dashboard.PeopleTab.title")}
            {/*Start chatting with our...*/}
          </div>
        </Col>
        <Col xs={6} className="text-right">
          <Button
            className={!showfitler ? "inFilterButton" : "inFilterButtonClicked"}
            icon={<FiFilter className="filterIcon" />}
            ghost
            style={{}}
            onClick={() => {
              setShowfilter(!showfitler);
            }}
          >
            {t("dashboard.PeopleTab.inFilterButton")}
          </Button>
        </Col>
      </Row>
      <div className="showFilterComponent">
        {showfitler && (
          <FilterComponent
            filterOptions={filterOptions}
            count={filterData.length}
            setFilterOptions={setFilterOptions}
          />
        )}
      </div>

      <Spin spinning={isFetching}>
        <div className="card-container">
          <Row style={{ width: "100%" }}>
            <Col span={24}>
              <Tabs
                className="tabs"
                style={{ color: `${payload?.theme?.themeColor}` }}
                type="card"
                size={"large"}
                defaultActiveKey="0"
                onChange={callback}
                // tabBarExtraContent={operations}
              >
                {payload?.univeristyConfig?.buddyTypes.map(
                  (tabItem: string, index) => {
                    return (
                      <TabPane
                        className="peopleTabStudentsTxt"
                        tab={
                          tabItem.charAt(0).toUpperCase() +
                          tabItem.slice(1).toLowerCase()
                        }
                        key={index}
                      >
                        <div className="peopleTab">
                          <Row gutter={16}>
                            {filteroutType(tabItem).length > 0 ? (
                              <Sonnet
                                des={tabItem}
                                data={filterData}
                                setSayhelloVisible={setSayhelloVisible}
                                setProfileId={setProfileId}
                              />
                            ) : (
                              <Empty
                                image={emptyImage}
                                description={`${t(
                                  "dashboard.PeopleTab.emptyTabText1"
                                )} ${tabItem} ${t(
                                  "dashboard.PeopleTab.emptyTabText2"
                                )}`}
                              />
                            )}
                          </Row>
                        </div>
                      </TabPane>
                    );
                  }
                )}
              </Tabs>
            </Col>
          </Row>
        </div>
        <Row>
          <Col span={24}>
            {filterData.length > 0 && (
              <Pagination
                defaultCurrent={1}
                total={buddyListPayload?.totalItems}
                onChange={onChangePagination}
                pageSize={12}
              />
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default PeopleTab;
