import {Avatar, Button, Dropdown, Input, List, Menu, Spin, Tag,} from "antd";
import React, {useEffect, useState} from "react";
import {BsThreeDotsVertical} from "react-icons/bs";
import "./BuddyList.scss";
import {clearGetAllBuddyList, getAllBuddyChatList, getAllBuddyListSelector,} from "./BuddyListSlice";
import {useAppDispatch} from "../../../../hooks";
import {useSelector} from "react-redux";
import {ChatBuddyProps} from "../../../Models/ChatBuddyProps";
import {getChatArea} from "../ChatArea/ChatAreaSlice";
import {setBuddyID} from "../SetSelectBuddySlice";
import {
    clearReadChatMessage,
    readChatMessage,
    readChatMessageSelector,
} from "../ChatArea/ReadMessage/ReadMessageSlice";
import {SearchOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {getAllFavMessages, getAllFavMessagesSelector} from "../getAllStartMessages/FavMessageSwitchSlice";
import FavMessageList from "../getAllStartMessages/FavMessageList";


function BuddyList({onClose} :any) {
    const dispatch = useAppDispatch();
    const [selectedId, setSelectedId] = useState<ChatBuddyProps>();
    const {t, i18n} = useTranslation();
    const {} = i18n;
    let { isFetching, buddyList} = useSelector(getAllBuddyListSelector);
    let { isFetching:favListIsFetching } = useSelector(getAllFavMessagesSelector);
    let {
        status: readMessageStatus,
    } = useSelector(readChatMessageSelector);
    const [filterList, setFilterList] = useState<ChatBuddyProps[]>(buddyList);
    const [search, setSearch] = useState<string>("");
    const [switchFavMessages, setSwitchFavMessages] = useState<boolean>(false);

    // function myFunction(buddy :any) {
    //     return {
    //         description: buddy.body,
    //         firstName: buddy.to,
    //         largeProfileUrl: "string",
    //         lastChatDate: "string",
    //         lastMessagePart: buddy.body,
    //         lastName: "string",
    //         mediumProfileUrl: "string",
    //         programme: "",
    //         publicId: buddy.id,
    //         replyTime: buddy.defaultReplyTime || 0,
    //         replyTimeUnit: "string",
    //         smallProfileUrl:"string",
    //         unreadCount: 0
    //     };
    // }
    useEffect(() => {
        console.log("buddyList", buddyList);
    }, [buddyList]);

    useEffect(() => {
        dispatch(getAllBuddyChatList());
    }, []);

    // useEffect(()=>{
    //     if(favListStatus ==="SUCCESS"){
    //         setFilterList(favMessageListResp.map(myFunction))
    //     }
    // },[favListStatus])

    useEffect(() => {
        if (selectedId?.publicId) {
            dispatch(getChatArea({id: parseInt(selectedId.publicId)}));
            dispatch(setBuddyID(selectedId));
            dispatch(
                readChatMessage({
                    buddyID: parseInt(selectedId.publicId),
                })
            );
            onClose(false)

        }
    }, [selectedId]);

    useEffect(() => {
        if (readMessageStatus === "SUCCESS") {
            dispatch(getAllBuddyChatList());
            dispatch(clearGetAllBuddyList());
            dispatch(clearReadChatMessage());
        }
    }, [readMessageStatus]);

    useEffect(() => {
        setFilterList(buddyList);
    }, [buddyList]);

    useEffect(() => {
        if(buddyList.length>0) {
            setFilterList(
                buddyList.filter(
                    (item: ChatBuddyProps) =>
                        item.firstName.toLowerCase().indexOf(search.toLowerCase()) > -1
                )
            )
        }
    }, [search]);


    function onBuddyClick(item:ChatBuddyProps) {
       setSelectedId(item)
    }

    function favMessageSWitch () {
        setSwitchFavMessages(!switchFavMessages)
        dispatch(getAllFavMessages())
    }

    return (
        <>
            {!switchFavMessages ?
                <List className={`buddyListLayoutSider`}>
                    <div className="buddiesListHeader">
                        <Input
                            className="buddiesSearchInput"
                            placeholder="Search Buddies"
                            onChange={(e) => setSearch(e.target.value)}
                            prefix={<SearchOutlined/>}
                        />
                        <Dropdown
                            overlay={<Menu>
                                <Menu.Item key="0">
                                    <div onClick={favMessageSWitch} className="dropDownShareButton">
                                        {t("Chat.BuddyList.starredDropDownBtn")}
                                    </div>
                                </Menu.Item>
                            </Menu>}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button className="menuButtonBuddie">
                                <BsThreeDotsVertical className="menuIconIn" size={25}/>
                            </Button>
                        </Dropdown>
                    </div>
                    <div className="myChatBuddiesView">
                        {filterList && filterList.length > 0 &&
                            <List
                                className="buddyListBody"
                                dataSource={filterList}
                                loading={isFetching}
                                renderItem={(item: ChatBuddyProps, index) => (
                                    <List.Item
                                        className={`listLayout ${item?.publicId === (selectedId?.publicId || 0) ? 'active' : ''}`}
                                        // className="listLayout active"
                                        // style={item?.publicId === (selectedId?.publicId || 0) ? {
                                        //     backgroundColor: '#09577c',
                                        //     color: '#fafafa'
                                        // } : {}}
                                        key={index}
                                        onClick={() => onBuddyClick(item)}
                                    >
                                        <List.Item.Meta
                                            className="buddiesInfo"
                                            avatar={<Avatar src={item.smallProfileUrl}/>}
                                            title={<a className="buddieName">{item.firstName}</a>}
                                            description={<div className="lastMessagePart">{item.lastMessagePart}</div>}
                                        />
                                        <div className="timeTagLayout">
                                            <p className="lastChatDate">{item.lastChatDate}</p>
                                            {item.unreadCount > 0 && (
                                                <Tag className="unreadCount">{item.unreadCount}</Tag>
                                            )}
                                        </div>
                                    </List.Item>
                                )}
                            />
                        }
                    </div>
                </List> :
                <Spin spinning={favListIsFetching}><FavMessageList setSwitchFavMessages={setSwitchFavMessages}/></Spin>

            }

        </>
    );
}

export default BuddyList;
