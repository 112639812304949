export type GetBuddlistProps = {
    "message": string
    "email": string
    "phone": string
    "name": string
    "channel": "EMAIL" | "SMS" | 'APP' | 'WhatsApp'
}


export const getAllChatBuddyListRequest = async () => {
    // let userDetails = JSON.parse(<string>localStorage.getItem("token"));
    // console.log("user",user)
    let sessionDetails = JSON.parse(localStorage.getItem('sessionDetails') as string)
    return await fetch(
        `${process.env.REACT_APP_API_URL}/chat/buddy`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'uniId' : process.env.REACT_APP_BASE_ID as string,
                'session' : sessionDetails?.sessionId
            }
        }
    )
        .then((res) => res.json())
};
