export interface SendEmailProps {
    "senderEmail":string,
    "receiverEmail":string,
    "message":string
}

export const sendEmailURLRequest = async (sendEmail:SendEmailProps) => {
    // let userDetails = JSON.parse(<string>localStorage.getItem("token"));
    // console.log("user",user)
    return await fetch(
        `${process.env.REACT_APP_API_URL}/support/email-share`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'uniId' : window.location.host as string,
            },
            body: JSON.stringify(sendEmail)
        }
    )
        .then((res) => res.json())
};
