import Ribbon from "antd/lib/badge/Ribbon";
import React from "react";
import "./TagType.scss"

export type TagProp = {
    type: "STUDENT" | "STAFF" | 'ALUMINI'
}
export const TagType = ({type}: TagProp) => {

    return <>{
        type === "STUDENT" ?
            <Ribbon
            className="tagType tagTypeMobile"
                text={'STUDENT'}
            /> :
            <Ribbon
                className="userRoleBadgeMobile"
                text={type}
                color="red"
            />
    }</>
}