import React, {useEffect, useState} from "react";
import {MdClose} from "react-icons/md";
import {Button, Col, Row, Select, Typography} from "antd";
import {useAppSelector} from "../../../hooks";
import {
    CoursesProp,
    getUniversityResourceSelector,
    Programmes,
    UniversityResourceState
} from "../../../MainScreen/MainScreenSlice";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

const {Title} = Typography;
const {Option} = Select;

function FilterComponent({filterOptions, setFilterOptions,count}: any) {

    const[courseList,setCourseList] = useState<CoursesProp[]>();

    useEffect(()=>{
        console.log("filterOptions",filterOptions)
    },[filterOptions])

    const clearFilters = () => {
      setFilterOptions({})
    }

    const { payload} = useAppSelector(
        getUniversityResourceSelector
    );

    function onChangeCountry(e: string){
        setFilterOptions({...filterOptions,country:e})
    }

    function onChangeProgramme(e: number){
        setFilterOptions({...filterOptions,programme:e})

    }

    function onChangeCourse(e: number){
        setFilterOptions({...filterOptions,course:e})
    }

    const {t} = useTranslation();

    useEffect(()=>{
        setFilterOptions({...filterOptions,course:[]})

        if(payload != undefined) {
            setCourseList(payload.programmes?.filter((item: Programmes) => item.id === filterOptions?.programme)[0]?.courses || [])
        }
    },[filterOptions.programme,payload])





    return (
        <div className={"filterLayout"}>
            <Row className="filterIn" gutter={10}>
                {payload?.univeristyConfig && payload?.univeristyConfig.countrySearchEnabled && (
                    <Col lg={8} xs={24}>
                        <Title level={5}>{t("dashboard.FilterComponent.filterTitleCountry")}</Title>
                        <Select
                            showSearch
                            showArrow
                            mode={'tags'}
                            style={{width: '100%'}}
                            onChange={onChangeCountry}
                            value={filterOptions.country}
                            onClear={() =>onChangeCountry("")}
                            allowClear={true}
                            placeholder={t("dashboard.FilterComponent.filterPlaceholder")}
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            {payload?.countries.map((country) => {
                                return <Option value={country.code}>{country.name}</Option>;
                            })}
                        </Select>
                    </Col>
                )}
                {payload?.univeristyConfig && payload?.univeristyConfig.programmeEnabled && (
                    <Col lg={8} xs={24}>
                        <Title level={5}>{t("dashboard.FilterComponent.filterTitleProgramme")}</Title>

                        <Select
                            showSearch
                            placeholder={t("dashboard.FilterComponent.filterPlaceholder")}
                            optionFilterProp="children"
                            onClear={() =>onChangeProgramme(-1)}
                            style={{width: '100%'}}
                            value={filterOptions.programme}
                            allowClear={true}
                            onChange={onChangeProgramme}
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            {payload?.programmes.map((programme) => {
                                return <Option value={programme.id}>{programme.name}</Option>;
                            })}
                        </Select>
                    </Col>
                )}
                {payload?.univeristyConfig && payload?.univeristyConfig.courseEnabled && (
                    <Col lg={8} xs={24}>
                        <Title level={5}>{t("dashboard.FilterComponent.filterTitleCourse")}</Title>
                        <Select
                            className="filter1"
                            showSearch
                            allowClear={true}
                            value={filterOptions.course}
                            style={{width: '100%'}}
                            placeholder={t("dashboard.FilterComponent.filterPlaceholder")}
                            optionFilterProp="children"
                            onChange={onChangeCourse}
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            {courseList?.map((course) => {
                                return <Option value={course.id}>{course.name}</Option>;
                            })}
                        </Select>
                    </Col>
                )}
                <Col className="search_footer">
                    <Typography className="show_result_count">{t("dashboard.FilterComponent.showResultCountText1")} {count} {t("dashboard.FilterComponent.showResultCountText2")}</Typography>
                   <div onClick={clearFilters} className="clear_search"> <Typography className="clear_search" ><MdClose />{t("dashboard.FilterComponent.filterClearSearch")}</Typography></div>
                </Col>
            </Row>
        </div>
    );
}

export default FilterComponent;
