

export const getAllFavMessagesAPI = async () => {
    // let userDetails = JSON.parse(<string>localStorage.getItem("token"));
    // console.log("user",user)
    let sessionDetails = JSON.parse(localStorage.getItem('sessionDetails') as string)

    return await fetch(
        `${process.env.REACT_APP_API_URL}/chat/star/history`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'uniId' : process.env.REACT_APP_BASE_ID as string,
                'session' : sessionDetails?.sessionId
            },
        }
    )
        .then((res) => res.json())
};
