import React, {useEffect, useState} from "react";
import "./App.scss";
import MainScreen from "./MainScreen/MainScreen";
import {Route, Routes} from "react-router-dom";
import ViewProfile from "./components/body/ViewProfileComponent/ViewProfile";
import PeopleTab from "./components/body/Home/PeopleTab/PeopleTab";
import SharePageEmailPopup from "./components/body/Home/SharePageEmailPopup/SharePageEmailPopup";
import MyChats from "./components/body/Home/MyChats/MyChats";
import Chat from "./components/body/Chat/Chat";
import {useAppSelector} from "./hooks";
import {registerProspectSelector} from "./components/body/RegisterProspect/RegisterProspectSlice";
import AddBuddyRating from "./components/body/Rating/AddBuddyRating";

export const IsUserLoggedContext = React.createContext(false)
function App() {
    const useSession = JSON.parse(localStorage.getItem("sessionDetails") as string)
    const [isLogged ,setisLogged] = useState(false)
    const {  status } = useAppSelector(registerProspectSelector);

useEffect(()=>{
    if(useSession) {
        setisLogged(true)
    }
    else {
        setisLogged(false)
    }
},[status,useSession])

    return (
        <IsUserLoggedContext.Provider value={isLogged} >
        <Routes>
            {/*<Route path="login" element={<Login/>}/>*/}
            <Route path="chat" element={<Chat/>}/>
            <Route path="/" element={<MainScreen/>}>
                <Route path="buddy/:id" element={<ViewProfile/>}/>
                <Route path="peopleTab" element={<PeopleTab/>}/>
                <Route path="sharePagePopup" element={<SharePageEmailPopup type={''}/>}/>
                <Route path="myChats" element={<MyChats/>}/>
                {/* <Route path="sayHelloPopup" element={<SayHelloPopup />} /> */}
            </Route>
            <Route path="buddy-rating" element={<AddBuddyRating/>}/>
        </Routes>
        </IsUserLoggedContext.Provider>
    );
}

export default App;
