import React, {useEffect, useState} from "react";
import {Button, Form as AntForm, Input, message, Modal} from "antd";
import {MdEmail} from "@react-icons/all-files/md/MdEmail";
import "./SharePageEmailPopup.scss";
import {FaShareAlt} from "@react-icons/all-files/fa/FaShareAlt";
import {Field, Form} from "react-final-form";
import {
    composeValidators,
    emptyArray,
    emptyList,
    required,
    validateEmail
} from "../../../../utils/Validations/Validations";
import {clearSendEmailURL, sendEMailUrl, sendEmailURLSelector} from "./SharePageEmailPopupSlice";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {WithContext as ReactTags} from 'react-tag-input';
import {useTranslation} from "react-i18next";
import {getUniversityResourceSelector} from "../../../../MainScreen/MainScreenSlice";


const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

type ShareEmailProps = {
    type:string
}

const SharePageEmailPopup = ({type} :ShareEmailProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useAppDispatch()
    const {isFetching, status} = useAppSelector(sendEmailURLSelector)
    const { payload} = useAppSelector(
        getUniversityResourceSelector
    );
    const showModal = () => {
        setIsModalVisible(true);
    };

    // const handleOk = () => {
    //     setIsModalVisible(false);
    // };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    function onSubmit(emailProps: any) {
        console.log("e", emailProps)
        dispatch(sendEMailUrl({...emailProps,receiverEmail:tags.map((item)=> item.text)}))
    }

    useEffect(() => {
        if (status === "SUCCESS") {
            setIsModalVisible(false)
            dispatch(clearSendEmailURL())
        }
        else if( status === "FAILED"){
            message.error(t(type ==="profile" ? "shareProfile.messageNotSent":"shareEmail.messageNotSent"))
            dispatch(clearSendEmailURL())

        }
    }, [status])

    const [tags, setTags] = React.useState<{id:string,text:string}[]>([]);

    const handleDelete = (i: number) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag: { id: string; text: string; }) => {
        setTags([...tags, tag]);
    };

    const handleDrag = (tag: { id: string; text: string; }, currPos: number, newPos: number) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };


    const {t} = useTranslation();


    return (
        <>
            <a className="dropDownShareButton" onClick={showModal}>
                <MdEmail className="emailIcon" size={18}/>
                <div className="dropDownShareButtonText">Email</div>
            </a>

            <Modal
                className="sharePageModelLayout"
                visible={isModalVisible}
                title={
                    <>
                        <div className="sharePageModelTitle">
                            <div className="sharePageText">{t(type === "profile" ? "shareProfile.header" :"shareEmail.header")}</div>
                        </div>
                    </>
                }
                onCancel={() => handleCancel()}
                footer={<div className="shareProfileFooter">
                    <Button
                        className="shareProfileButton"
                        loading={isFetching}
                        type="primary"
                        htmlType={"submit"}
                        style={{background: `${payload?.theme?.themeColor}`}}
                        form="sendEmailForm"
                        //
                        // onClick={() => message.success (<MessageSuccessPopup />)}
                        icon={<FaShareAlt size={18} style={{paddingRight: "5px"}}/>}
                        size="large"
                    >
                        <div className="shareprofileText">{t(type === "profile" ? "shareProfile.header" :"shareEmail.header")}</div>
                    </Button>
                </div>}
            >
                <Form
                    onSubmit={onSubmit}
                    validate={(values: any) => {
                        console.log('values>>>', values)
                        const errors = {
                            senderEmail: undefined,
                            receiverEmail: undefined,
                        }

                        if (tags.length ===0) {
                            // @ts-ignore
                            // errors.senderEmail = 'error.validation.sameEmail'
                            // @ts-ignore
                            errors.receiverEmail = 'error.validation.required'
                        }

                        for (let i = 0; i <  tags.length; i++) {
                            // @ts-ignore
                            errors.receiverEmail = validateEmail(tags[i].text)
                        }
                        return errors

                    }
                    }

                    render={({handleSubmit, values, hasValidationErrors, errors,}) => (
                        <form onSubmit={handleSubmit} className='form' encType="multipart/form-data"
                              id={"sendEmailForm"}>
                            <AntForm>
                                <div className="sharePageModel">
                                    <div className="emailToTextBox">
                                        <Field name="receiverEmail"
                                               validate={composeValidators(emptyList,emptyArray)}>
                                            {({input, meta, ...rest}) => (
                                                <AntForm.Item label={'Email To'}
                                                              required={true}
                                                              validateStatus={meta.touched && meta.error ? "error" : ""}
                                                              help={meta.touched && meta.error && t(meta.error)}
                                                              name="receiverEmail" wrapperCol={{offset: 0, span: 24}}
                                                              labelCol={{span: 24}}>

                                                    {/*<ListInput />*/}
                                                    <ReactTags
                                                        tags={tags}
                                                        placeholder={'Enter share to email addresses'}
                                                        delimiters={delimiters}
                                                        handleDelete={handleDelete}
                                                        handleAddition={handleAddition}
                                                        handleDrag={handleDrag}
                                                        inputFieldPosition="bottom"
                                                    />

                                                    {meta.touched && meta.error && <span></span>}
                                                </AntForm.Item>
                                            )}
                                        </Field>
                                    </div>

                                    <div className="yourEmailAddress">
                                        <Field name="senderEmail"
                                               validate={composeValidators(required, (input: string) => validateEmail(input))}>
                                            {({input, meta, ...rest}) => (
                                                <AntForm.Item label={'Your Email Address'}
                                                              required={true}
                                                              validateStatus={meta.touched && meta.error ? "error" : ""}
                                                              help={meta.touched && meta.error && t(meta.error)}
                                                              name="senderEmail" wrapperCol={{offset: 0, span: 24}}
                                                              labelCol={{span: 24}}>
                                                    <Input className='inpt' {...meta} {...rest} {...input}
                                                           onKeyPress={(e) => {
                                                               e.key === 'Enter' && e.preventDefault();
                                                           }}
                                                           placeholder={'Enter your email address'}/>

                                                    {meta.touched && meta.error && <span></span>}
                                                </AntForm.Item>
                                            )}
                                        </Field>
                                    </div>

                                    <div className="yourMessages">
                                        <div className="emailToTextBox">
                                            <Field name="message"
                                                  >
                                                {({input, meta, ...rest}) => (
                                                    <AntForm.Item label={'Your Message'}
                                                                  validateStatus={meta.touched && meta.error ? "error" : ""}
                                                                  help={meta.touched && meta.error && t(meta.error)}
                                                                  name="message" wrapperCol={{offset: 0, span: 24}}
                                                                  labelCol={{span: 24}}>
                                                        <Input.TextArea className='inpt' {...meta} {...rest} {...input}
                                                                        onKeyPress={(e) => {
                                                                            e.key === 'Enter' && e.preventDefault();
                                                                        }}
                                                                        placeholder={'Enter your message'}/>

                                                        {meta.touched && meta.error && <span></span>}
                                                    </AntForm.Item>
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <>

                                </>
                            </AntForm>
                        </form>
                    )}
                />
            </Modal>
        </>
    );
};

export default SharePageEmailPopup;
