export type UniversityResourceProps = {
    id: string
}


export const getUniversityResourceRequest = async (universityResourceProps: UniversityResourceProps) => {
    // let userDetails = JSON.parse(<string>localStorage.getItem("token"));
    // console.log("user",user)
    // const res = await fetch("https://geolocation-db.com/json/",{
    //     method:'GET'
    // })
    return await fetch(
        `${process.env.REACT_APP_API_URL}/university/${universityResourceProps.id}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*'
            },
            // body: JSON.stringify({
            //     auth_token:user.auth_token
            // }),
        }
    )
        .then((res) => res.json())
};
