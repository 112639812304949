import React, { useEffect, useState } from "react";
import "../App.scss";
import { Layout } from "antd";
import Body from "../components/body/Body";

import {
  fetchUniversityResourceById,
  getUniversityResourceSelector,
} from "./MainScreenSlice";
import { useDispatch } from "react-redux";
import "./MainScreen.scss";
import "./MobileMenu";
import { useAppSelector } from "../hooks";
import CustomFooter from "../components/common/Footer/Footer";
import i18next from "i18next";
import HeaderMain from "../components/common/HeaderMain/HeaderMain";

const { Content } = Layout;

function MainScreen() {
  const [enableWordPress, setEnableWordPress] = useState<any>(false);
  // const {id} = useParams();
  let dispatch = useDispatch();
  const { payload } = useAppSelector(getUniversityResourceSelector);

  useEffect(() => {
    console.log(payload);
  }, [payload]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const wordPress = searchParams.get("wordpress");

    if (wordPress) {
      setEnableWordPress(wordPress);
    }

    if (window.location.pathname) {
      // dispatch(fetchUniversityResourceById(window.location.host));
      dispatch(
        fetchUniversityResourceById(process.env.REACT_APP_BASE_ID as string)
      );
    }
  }, []);

  const changeLanguage = (e: any) => {
    i18next.changeLanguage(e);
  };

  return (
    <div>
      <Layout>
        {!enableWordPress ? <HeaderMain /> : <></>}

        <Layout className="mainLayout">
          <Content>
            <Body />
          </Content>
        </Layout>
        {!enableWordPress ? <CustomFooter /> : <></>}
      </Layout>
    </div>
  );
}

export default MainScreen;
