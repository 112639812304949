import "./MyChats.scss";
import React from "react";
import {Avatar, Col, Input, List, Row,} from "antd";
import {UserOutlined} from "@ant-design/icons";

// const { Header, Content, Footer, Sider } = Layout;

const data = [
  {
    title: "Ant Design Title 1",
  },
  {
    title: "Ant Design Title 2",
  },
  {
    title: "Ant Design Title 3",
  },
  {
    title: "Ant Design Title 4",
  },
];


function MyChats() {
  return (
    <>
      {/* <Content> */}
        <div className="MyChatBuddiesViewLayout">
          {/* <Row> */}
          <Col span={24} sm={24} md={12}>
            <Row>
              <div className="MyChatBuddiesView">BuddiesSearchInput
                <div className="BuddiesListHeader">
                <Input className="BuddiesSearchInput" placeholder="Search" />
                </div>
                
                <List
                
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item className="listLayout">
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            icon={<UserOutlined />}
                            src={
                              "https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                            }
                          />
                        }
                        title={<a href="https://ant.design">{item.title}</a>}
                        description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Row>
          </Col>

          {/* <Col span={8} className="leftColumnBackround">
            <div className="leftColumn">
              <div className="leftColumnWidget">
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <div className="dropDownProfileButton ">
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <FaShareAlt size={18} style={{ paddingRight: "5px" }} />
                      <span className="dropDownIcon">Share</span>

                      <DownOutlined />
                    </a>
                  </div>
                </Dropdown>
                <span className="sendMessageButton"></span>
              </div>
            </div>
          </Col> */}
        </div>
        {/* </Row> */}
      {/* </Content> */}
    </>
  );
}

export default MyChats;
