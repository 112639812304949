import React, {memo, useContext, useEffect, useState} from "react";
import "./SayHelloPopup.scss";
import {Button, Checkbox, Col, message, Modal, Popover, Row, Steps,} from "antd";
import {PersonProp} from "../PeopleTab/PeopleTabSlice";
import TypeMessageContainer from "./TypeMessageContainer";
import YourSelfContainer from "./YourSelfContainer";
import {FiSend} from "@react-icons/all-files/fi/FiSend";
import {IoIosArrowForward} from "@react-icons/all-files/io/IoIosArrowForward";
import {MdArrowBackIos} from "react-icons/md";
import {useDispatch, useSelector} from "react-redux";
import {RegisterProps} from "../../../Models/RegisterProps";

import {
    clearRegisterProspects,
    registerProspectSelector,
    registerProspectUser, setRegisterSuccessAfterOTPValidate,
} from "../../RegisterProspect/RegisterProspectSlice";
import useProfileImage from "../../../../assets/images/default_user_image.svg";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../hooks";
import {getUniversityResourceSelector} from "../../../../MainScreen/MainScreenSlice";
import {IsUserLoggedContext} from "../../../../App";
import {
    clearSendChatMessage,
    sendChatMessage,
    sendChatMessageSelector
} from "../../Chat/ChatArea/SendMessage/SendMessageSlice";
import MobileVerification from "./MobileVerification";
import {clearValidateOTP, validateOTP, validateOTPSelector} from "./ValidateOTP/ValidateOTPSlice";
import {useSearchParams} from "react-router-dom";

const steps = [
    {
        title: "Message",
        content: "First-content",
    },
    {
        title: "Your Information",
        content: "Second-content",
    },
    {
        title: "Mobile Verification",
        content: "Second-content",
    },
];

const {Step} = Steps;

type SayHelloPopupProps = {
    buddies: any;
    visible: boolean;
    setVisible: (isVisible: boolean, isSuccess: boolean) => void;
    id: number;
};

function SayHelloPopup({
                           buddies,
                           setVisible,
                           visible,
                           id
                       }: SayHelloPopupProps) {
    const [searchParams, ] = useSearchParams()

    const refKey = searchParams.get("refKey")

    const {payload} = useAppSelector(getUniversityResourceSelector);
    const [buddyProfile, setBuddyProfile] = useState<PersonProp>();
    const [current, setCurrent] = React.useState(0);
    const [checked, setChecked] = useState<boolean>(false);
    const [messageContent, setMessageContent] = useState<string>("");
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [isOTPValidated, setIsOTPValidated] = useState<boolean | undefined>(undefined);
    const universityConfigResponse = useSelector(getUniversityResourceSelector);
    const {status, isFetching, sessionDetails,error} = useSelector(
        registerProspectSelector
    );

    const validateOTPResponse = useSelector(validateOTPSelector);

    const { status: sendMessageStatus, isFetching: isSendingMessage} = useSelector(
        sendChatMessageSelector
    );
    const [, SetUserTermsVisiblity] = useState(false);
    const [reqRequest, setReqRequest] = useState<RegisterProps>({
        id: 0,
        message: "",
        channels: ["EMAIL"],
        email: "",
        name: "",
        phone: "",
    });
    const dispatch = useDispatch();

    function onSendRequest(reqRequest: RegisterProps) {
        dispatch(registerProspectUser({...reqRequest, id: id}));
    }

    const {t} = useTranslation();

    const isLoggedIn = useContext(IsUserLoggedContext);

    useEffect(()=>{
        // message.error(error?.message)
    },[error])

    useEffect(() => {
        if (id) {
            setReqRequest({...reqRequest, id: id});
            setBuddyProfile(buddies);
        }
    }, [id]);

    useEffect(() => {
        if (!!refKey) {
            setReqRequest(prevState => ({...prevState, refKey}))
        }
    }, [refKey]);

    useEffect(() => {
        if (status === "SUCCESS" && !!sessionDetails && isOTPValidated) {
            dispatch(clearRegisterProspects());
        } else if (status === "FAILED") {

            message.error(error?.message);
            dispatch(clearRegisterProspects());
        }
    }, [status, isOTPValidated]);


    useEffect(() => {
        if (sendMessageStatus === "SUCCESS") {
            setVisible(false, true);
            dispatch(clearSendChatMessage())
        }
    }, [sendMessageStatus])

    useEffect(() => {
        if (messageContent?.length > 0 && (checked || isLoggedIn)) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
    }, [messageContent, checked])

    const handleCancel = () => {
        if (current === 1) {
            prev();
        }

        setVisible(false, false);
    };

    const next = (isLogged: boolean) => {
        if (!isLogged) {
            setCurrent(current + 1);
            SetUserTermsVisiblity(true);
        } else {
            setCurrent(0)
            dispatch(
                sendChatMessage({
                    buddyID: id,
                    message: messageContent,
                })
            );
        }
    };

    const prev = () => {
        setCurrent(current - 1);
        SetUserTermsVisiblity(false);
    };

    function stepMaker(stepNo: number) {
        switch (stepNo) {
            case 0:
                return (
                    <TypeMessageContainer
                        messageState={messageContent}
                        setMessageState={setMessageContent}
                    />
                );
                break;
            case 1:
                return (
                    <YourSelfContainer
                        onNext={regProps => {
                            if (!!universityConfigResponse && !!universityConfigResponse.payload && universityConfigResponse.payload.univeristyConfig.prospectVerificationEnabled) {
                                onSendRequest({...regProps, id: id})
                                setReqRequest({id: id, ...regProps})
                                next(isLoggedIn);
                            } else {
                                onSendRequest({...regProps, id: id})
                            }
                        }}
                        messageContent={messageContent}
                        initValue={reqRequest}
                    />
                );
                break;
            case 2:
                return ( !!sessionDetails ?
                    <MobileVerification
                        onSubmit={otp => {doValidateOTP(otp)}}
                        otpValidationState={isOTPValidated}
                        phoneNumber={reqRequest.phone}
                        pid={sessionDetails.prospectId}
                    />: <></>
                );
        }
    }

    function doValidateOTP(otp: string) {
        if (!!sessionDetails) {
            dispatch(validateOTP({code: otp, pid: sessionDetails.prospectId}))
        }
    }

    useEffect(() => {
        if (validateOTPResponse.response === "Success") {
            dispatch(setRegisterSuccessAfterOTPValidate())
            setIsOTPValidated(true)
            dispatch(clearValidateOTP());
            setVisible(false, true);
        } else if (validateOTPResponse.status === "SUCCESS") {
            setIsOTPValidated(false);
        }
    }, [validateOTPResponse]);

    useEffect(() => {
        if (!visible) {
            setMessageContent("");
        }
    }, [visible]);

    useEffect(() => {
        if (messageContent) {
            setReqRequest({...reqRequest, message: messageContent});
        }

    }, [messageContent]);

    const content = (
        <div className="chatRulesPopoverLayout">
            <div className="dosTextHeader">
                {t("dashboard.SayHelloPopup.dosTextHeader")}
            </div>
            <div className="dosTextDescrip">
                {t("dashboard.SayHelloPopup.dosTextDescrip")}{" "}
            </div>
            <div className="dontsTextHeader">
                {t("dashboard.SayHelloPopup.dontsTextHeader")}
            </div>
            <div className="dontsTextDescrip">
                {t("dashboard.SayHelloPopup.dontsTextDescrip")}{" "}
            </div>
        </div>
    );

    return (
        <div key={id}>
            <Modal
                    className="sayHelloPopupLayout"
                    visible={visible}
                    title={
                        <div className="sayHelloPopupTitle">
                            <div className="sayHelloPopupImg">
                                <img
                                    className="sayHelloPopupImgIn sayHelloPopupImgMobileIn"
                                    src={buddyProfile?.profile.smallProfileUrl || useProfileImage}
                                    alt="empty"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = useProfileImage;
                                    }}
                                />
                            </div>
                            <div className="sayHelloPopupTitleText">
                                <div className="sayHelloPopupTitleTextIn">
                                    {t("dashboard.SayHelloPopup.sayHelloPopupTitleText")}{" "}
                                </div>
                                {!isLoggedIn && <div className="sayHelloPopupTitleSubTextIn">
                                    {" "}
                                    {t("dashboard.SayHelloPopup.sayHelloPopupTitleSubText")}{" "}
                                    {buddyProfile?.replyTime.count} {buddyProfile?.replyTime.unit}
                                </div>}
                            </div>
                        </div>
                    }
                    onCancel={handleCancel}
                    footer={[
                        <div className="steps-action">
                            {current === 0 &&
                                <>
                                    <Row align="middle" style={{justifyContent: "flex-end"}}>
                                        {!isLoggedIn && <Col lg={15} xs={24}>
                                            <Checkbox
                                                className="Checkbox"
                                                checked={checked}
                                                onChange={() => setChecked(!checked)}
                                            >
                                                <div className="CheckboxText">
                                                    <span> {t("dashboard.SayHelloPopup.checkboxText")} </span>
                                                    <a
                                                        className="termsOfUseText"
                                                        href={process.env.REACT_APP_TERMS_AND_CONDITION_URL}
                                                        rel="noreferrer"
                                                        style={{color: `${payload?.theme?.themeColor}`}}
                                                        target="_blank"
                                                    >
                                                        {t("dashboard.SayHelloPopup.termsOfUseText")}
                                                    </a>{" "}
                                                    <a
                                                        className="privacyPolicyText"
                                                        href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                                                        rel="noreferrer"
                                                        style={{color: `${payload?.theme?.themeColor}`}}
                                                        target="_blank"
                                                    >
                                                        {t("dashboard.SayHelloPopup.privacyPolicyText")}
                                                    </a>{" "}
                                                    {t("dashboard.SayHelloPopup.privacyPolicyTextAnd")}{" "}
                                                    <Popover
                                                        className="popoverLayout"
                                                        placement="bottomRight"
                                                        content={content}
                                                        trigger="click"
                                                    >
                                                        <a
                                                            className="chatRulesText"
                                                            href="Chat Rules"
                                                            style={{
                                                                color: `${payload?.theme?.themeColor}`,
                                                            }}
                                                        >
                                                            {t("dashboard.SayHelloPopup.chatRulesText")}
                                                        </a>
                                                    </Popover>
                                                </div>
                                            </Checkbox>
                                        </Col>}

                                        <Col lg={9} xs={24}>
                                            <div className="modalStepsActions">
                                                <Button
                                                    className="btnClose btn-lg"
                                                    type="primary"
                                                    ghost
                                                    onClick={() => handleCancel()}
                                                    style={{
                                                        color: `${payload?.theme?.themeColor}`,
                                                        borderColor: `${payload?.theme?.themeColor}`,
                                                    }}
                                                >
                                                    {t("dashboard.SayHelloPopup.btnClose")}
                                                </Button>

                                                {!isLoggedIn ?
                                                    <Button
                                                        className="btnContinue btn-lg"
                                                        type="primary"
                                                        disabled={disableButton}
                                                        onClick={() => next(false)}
                                                        style={{
                                                            backgroundColor: `${payload?.theme?.themeColor}`,
                                                            borderColor: `${payload?.theme?.themeColor}`,
                                                        }}
                                                    >
                                                        <div className="btnContinueText">
                                                            {" "}
                                                            {t("dashboard.SayHelloPopup.btnContinue")}
                                                        </div>
                                                        <IoIosArrowForward/>
                                                    </Button>
                                                    : <Button
                                                        className="btnContinue btn-lg"
                                                        loading={isSendingMessage}
                                                        type="primary"
                                                        disabled={disableButton}
                                                        onClick={() => next(isLoggedIn)}
                                                        icon={<FiSend/>}
                                                        style={{
                                                            backgroundColor: `${payload?.theme?.themeColor}`,
                                                            borderColor: `${payload?.theme?.themeColor}`,
                                                        }}
                                                    >
                                                        <span className="btnContinueText">
                                                            {" "}
                                                            {t("dashboard.SayHelloPopup.btnSendMessage")}{" "}
                                                        </span>
                                                    </Button>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            }

                            <span className="secondStepFooter">
                                {current > 0 && (
                                    <Button
                                        className="sayHelloPopuBackButton btn-lg"
                                        onClick={() => prev()}
                                        style={{
                                            color: `${payload?.theme?.themeColor}`,
                                            borderColor: `${payload?.theme?.themeColor}`,
                                        }}
                                    >
                                        <MdArrowBackIos/>
                                        {t("dashboard.SayHelloPopup.btnBackButton")}{" "}
                                    </Button>
                                )}
                                {current === 1 && !!universityConfigResponse && !!universityConfigResponse.payload && universityConfigResponse.payload.univeristyConfig.prospectVerificationEnabled && <Button
                                    className="btnContinue btn-lg"
                                    type="primary"
                                    loading={isFetching}
                                    disabled={disableButton}
                                    htmlType={"submit"}
                                    form="addSummeryForm"
                                    style={{
                                        backgroundColor: `${payload?.theme?.themeColor}`,
                                        borderColor: `${payload?.theme?.themeColor}`,
                                    }}
                                >
                                    <div className="btnContinueText">
                                        {" "}
                                        {t("dashboard.SayHelloPopup.btnContinue")}
                                    </div>
                                    <IoIosArrowForward/>
                                </Button>}
                                {current === 1 && !!universityConfigResponse && !!universityConfigResponse.payload && !universityConfigResponse.payload.univeristyConfig.prospectVerificationEnabled &&
                                    <Button
                                        className="btnSendMessage btn-lg"
                                        loading={isFetching}
                                        type="primary"
                                        disabled={disableButton}
                                        htmlType={"submit"}
                                        form="addSummeryForm"
                                        icon={<FiSend/>}
                                        style={{
                                            backgroundColor: `${payload?.theme?.themeColor}`,
                                            borderColor: `${payload?.theme?.themeColor}`,
                                        }}
                                    >
                                    <span className="sendMessageButtonText">
                                {" "}
                                        {t("dashboard.SayHelloPopup.btnSendMessage")}{" "}
                                    </span>
                                    </Button>}
                                {current === steps.length - 1 && !!universityConfigResponse && !!universityConfigResponse.payload && universityConfigResponse.payload.univeristyConfig.prospectVerificationEnabled &&(
                                    <Button
                                        className="btnSendMessage btn-lg"
                                        loading={isFetching}
                                        type="primary"
                                        htmlType={"submit"}
                                        form="otpForm"
                                        icon={<FiSend/>}
                                        style={{
                                            backgroundColor: `${payload?.theme?.themeColor}`,
                                            borderColor: `${payload?.theme?.themeColor}`,
                                        }}
                                    >
                                        <span className="sendMessageButtonText">
                                            {" "}
                                            {t("dashboard.SayHelloPopup.btnSendMessage")}{" "}
                                        </span>
                                    </Button>
                                )}
                            </span>
                        </div>,
                    ]}
                >
                    <Steps current={current} style={{marginBottom: 12}}>
                        {!isLoggedIn && steps.map((item) => (
                            <Step key={item.title} title={item.title}/>
                        ))}
                    </Steps>
                    <div className="steps-content">{stepMaker(current)}</div>
                </Modal>
        </div>
    );
}

export default memo(SayHelloPopup);

