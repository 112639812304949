import "./ViewProfile.scss";
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Dropdown,
  Layout,
  Menu,
  message,
  Row,
  Spin,
  Tabs,
  Tooltip,
  Typography,
  Rate,
  Divider,
  Popover,
} from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import SayHelloPopup from "../Home/SayHelloPopup/SayHelloPopup";
import { BiArrowBack } from "@react-icons/all-files/bi/BiArrowBack";
import Ribbon from "antd/lib/badge/Ribbon";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import SharePageEmailPopup from "../Home/SharePageEmailPopup/SharePageEmailPopup";
import { FaCopy } from "@react-icons/all-files/fa/FaCopy";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import { getUniversityResourceSelector } from "../../../MainScreen/MainScreenSlice";
import {
  fetchBuddyDetailsById,
  getBuddyViewSelector,
} from "./ViewProfileSlice";
import { useDispatch } from "react-redux";
import iconRate from "../../../assets/images/icon-rate.svg";
import { FiSend } from "@react-icons/all-files/fi/FiSend";
import { useTranslation } from "react-i18next";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BsShareFill } from "react-icons/bs";

import CopyToClipboard from "react-copy-to-clipboard";
import defaultCoverPic from "../../../assets/images/cover-photo-large.jpeg";
import MessageSuccessPopup from "../Home/SayHelloPopup/MessageSuccessPopup";
import moment from "moment";
import topRatedBadgePic from "../../../assets//images/badges/top-rated.svg";
import creatorBadgePic from "../../../assets//images/badges/creator.svg";
import influencerBadgePic from "../../../assets//images/badges/influencer.svg";
import courseCompletedBadgePic from "../../../assets//images/badges/course-completed.svg";
import expertBadgePic from "../../../assets//images/badges/expert.svg";

const { Header, Content, Footer, Sider } = Layout;

const { TabPane } = Tabs;

const info = () => {
  message.info("Content copied to clipboard");
};

const { Title } = Typography;

function callback(key: any) {
  console.log(key);
}

function ViewProfile() {
  const navigate = useNavigate();
  const { id } = useParams();
  let dispatch = useDispatch();
  const [sayhelloVisible, setSayhelloVisible] = useState<boolean>(false);
  const [isMessageSuccessModalVisible, setIsMessageSuccessModalVisible] =
    useState<boolean>(false);

  const { isFetching, status, payload } = useAppSelector(
    getUniversityResourceSelector
  );
  const { buddyView, buddyViewFetchStatus, isFetchingBuddyView } =
    useAppSelector(getBuddyViewSelector);

  useEffect(() => {
    console.log("LL", id);
    const univercity = process.env.REACT_APP_BASE_ID;
    dispatch(
      fetchBuddyDetailsById({
        id: id || "",
        universityId: univercity as string,
      })
    );
  }, []);

  const { t } = useTranslation();

  const menu = (
    <div className="shareDropdownLayout">
      <Menu className="shareDropdown">
        <div>
          <Menu.Item key="0">
            <div className="FacebookShareButtonLayout">
              <FacebookShareButton
                className="dropDownShareButtonFb"
                url={buddyView.profileLink as string}
              >
                <FaFacebookSquare className="facebookIcon" size={18} />
                <div className="dropDownShareButtonText">Facebook</div>
              </FacebookShareButton>
            </div>
          </Menu.Item>

          <Menu.Item key="2">
            <TwitterShareButton
              className="dropDownShareButtonFb"
              url={buddyView.profileLink as string}
            >
              <AiFillTwitterCircle className="twitterIcon" size={18} />
              <div className="dropDownShareButtonText">Twitter</div>
            </TwitterShareButton>
          </Menu.Item>

          <Menu.Item key="3">
            <SharePageEmailPopup type="profile" />
          </Menu.Item>
          <Menu.Item key="4">
            <CopyToClipboard
              text={buddyView.profileLink as string}
              onCopy={info}
            >
              <button
                className="dropDownShareButtonCopyUrl"
                onClick={() => info}
              >
                <FaCopy className="copyUrlIcon" size={16} />
                <div className="dropDownShareButtonText">Copy Url</div>
              </button>
            </CopyToClipboard>
          </Menu.Item>
        </div>
      </Menu>
    </div>
  );

  const renderRatings = (ratings: any) => {
    return (
      <>
        {ratings &&
          ratings?.map((item: any, key: any) => {
            return (
              <>
                <div key={key}>
                  <div>
                    {item?.prospectName} {item?.ratings}/5{" "}
                    <Rate defaultValue={item?.ratings} />{" "}
                    <span style={{ float: "right" }}>
                      {moment(item?.createdDate).format("MMM Do, YYYY")}
                    </span>
                  </div>
                  <div>{item?.comment}</div>
                </div>
                <Divider />
              </>
            );
          })}
      </>
    );
  };

  const badgesContent = (
    <div className="badgesPopover">
      <div className="badgesPopoverBadge">
        <img className="" alt="User-badge" src={topRatedBadgePic} />
        <p>Top Rated</p>
      </div>
      <div className="badgesPopoverBadge">
        <img className="" alt="User-badge" src={creatorBadgePic} />
        <p>Content Creator</p>
      </div>
      <div className="badgesPopoverBadge">
        <img className="" alt="User-badge" src={influencerBadgePic} />
        <p>Influencer</p>
      </div>
      <div className="badgesPopoverBadge">
        <img className="" alt="User-badge" src={courseCompletedBadgePic} />
        <p>Couse Comleted</p>
      </div>
      <div className="badgesPopoverBadge">
        <img className="" alt="User-badge" src={expertBadgePic} />
        <p>Expert</p>
      </div>
    </div>
  );

  return (
    <>
      <MessageSuccessPopup
        isModalVisible={isMessageSuccessModalVisible}
        setIsModalVisible={setIsMessageSuccessModalVisible}
      />
      {buddyViewFetchStatus === "success" && (
        <SayHelloPopup
          buddies={buddyView}
          visible={sayhelloVisible}
          setVisible={(isVisible, isSuccess) => {
            setSayhelloVisible(isVisible);
            setIsMessageSuccessModalVisible(isSuccess);
          }}
          id={parseInt(id || "0")}
        />
      )}

      <Spin spinning={isFetchingBuddyView}>
        <Content className="viewProfile">
          <Row>
            <Tooltip title="Back">
              <Button
                onClick={() => navigate(-1)}
                className="backButton"
                shape="circle"
                icon={<BiArrowBack />}
                size="large"
              />
            </Tooltip>
            {/* <img
              className="backroundCover "
              src={buddyView?.profile?.banner?.imageUrl}
              alt="backroundCover"
            /> */}
            <div className="bannerWrapper">
              <div className="bannerImgWrapper">
                <img
                  src={buddyView?.profile?.banner?.imageUrl || defaultCoverPic}
                  alt={
                    buddyView?.profile?.firstName &&
                    buddyView?.profile?.lastName + "'s cover image"
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = defaultCoverPic;
                  }}
                  className="backroundCover"
                />
              </div>
              <Row className="avatarRow">
                <Avatar
                  className="profileImage"
                  icon={<UserOutlined />}
                  src={buddyView?.profile?.smallProfileUrl}
                />
                <div className="profileDetails">
                  <div className="profileName">
                    {buddyView?.profile?.firstName}{" "}
                    {buddyView?.profile?.lastName}
                    {/* <Button className="shareButtonMobile" ghost>
                      <FaShareAlt className="shareButtonMobileIcon" size={20} />
                    </Button> */}
                  </div>
                  <Ribbon
                    className="profileBadge"
                    text={buddyView?.profile?.type}
                  ></Ribbon>
                  <div className="profileEarnedBadges d-none">
                      <img className="" alt="User-badge" src={topRatedBadgePic} />
                      <img className="" alt="User-badge" src={creatorBadgePic} />
                      <img className="" alt="User-badge" src={influencerBadgePic} />
                      <Popover
                        content={badgesContent}
                      >
                        <div className="moreBtn">+2</div>
                      </Popover>
                  </div>
                </div>
              </Row>
            </div>
          </Row>

          <div className="profileTab">
            <Row>
              <Col span={24}>
                <Tabs
                  className="tabArea"
                  defaultActiveKey="1"
                  onChange={callback}
                  size={"large"}
                  tabBarExtraContent={
                    <div className="btnArea">
                      <Dropdown
                        overlay={menu}
                        trigger={["click"]}
                        placement="bottomRight"
                      >
                        <div
                          className="dropDownProfileButton "
                          style={{
                            borderColor: `${payload?.theme?.themeColor}`,
                          }}
                        >
                          <a
                            className="btnShareProfile"
                            href="Share button"
                            onClick={(e) => e.preventDefault()}
                            style={{
                              color: `${payload?.theme?.themeColor}`,
                              borderColor: `${payload?.theme?.themeColor}`,
                            }}
                          >
                            <BsShareFill />
                            <span className="dropDownIcon">
                              <span>{t("ViewProfile.btnShareDropdown")}</span>
                            </span>

                            <DownOutlined />
                          </a>
                        </div>
                      </Dropdown>
                      <Button
                        className="sendMsgBtn"
                        type="primary"
                        onClick={() => setSayhelloVisible(true)}
                        icon={<FiSend />}
                        size="large"
                        style={{
                          backgroundColor: `${payload?.theme?.themeColor}`,
                          borderColor: `${payload?.theme?.themeColor}`,
                        }}
                      >
                        {t("ViewProfile.btnSendMessage")}
                      </Button>
                    </div>
                  }
                >
                  <TabPane tab={t("ViewProfile.profileTabText")} key="1">
                    <Row gutter={16}>
                      <Col xs={24} lg={12}>
                        <Title level={5}>Profile</Title>
                        <div className="LeftCol">
                          <div className="ratedColumn">
                            <div className="flexRow">
                              <div className="buddyColumn">
                                <img src={iconRate} alt="" />
                                <div
                                  className="ratingText"
                                  style={{
                                    color: `${payload?.theme?.themeColor}`,
                                    borderColor: `${payload?.theme?.themeColor}`,
                                  }}
                                >
                                  <div className="rateCount">
                                    {buddyView?.helpProspectCount}
                                  </div>
                                  <div className="prospectResponse">
                                    {t("ViewProfile.prospectResponse")}
                                  </div>
                                </div>
                              </div>

                              {/* <Divider
                                className="divider"
                                style={{ borderColor: "grey", height: "40px" }}
                                type="vertical"
                              /> */}

                              <div className="buddyReplyTime">
                                {t("ViewProfile.buddyReplyTime")}{" "}
                                {buddyView.replyTime.count}{" "}
                                {buddyView.replyTime.unit}
                              </div>
                            </div>
                          </div>
                          <div className="buddyProfileTable">
                            <div className="tableInner">
                              <Descriptions>
                                <Descriptions.Item
                                  label={t("ViewProfile.buddyName")}
                                  span={3}
                                >
                                  {buddyView?.profile?.firstName}{" "}
                                  {buddyView?.profile?.lastName}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={t("ViewProfile.buddyProgramme")}
                                  span={3}
                                >
                                  {buddyView?.profile?.programme}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={t("ViewProfile.buddyCourse")}
                                  span={3}
                                >
                                  {buddyView?.profile?.courseInfo?.courseTitle}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={t("ViewProfile.buddyCourseYear")}
                                  span={3}
                                >
                                  {buddyView?.profile?.year}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={t("ViewProfile.languageISpeak")}
                                  span={3}
                                >
                                  {buddyView?.profile?.languages.map((lang) => {
                                    return <span className="lang">{lang}</span>;
                                  })}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={t("ViewProfile.buddyInterest")}
                                  span={3}
                                >
                                  <div className="hobbyIn">
                                    <div className="hobbyIcon">
                                      {buddyView?.profile?.interests.map(
                                        ({ iconUrl, name }) => {
                                          return (
                                            <Tooltip
                                              placement="bottom"
                                              title={name}
                                            >
                                              <img src={iconUrl} />
                                            </Tooltip>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </Descriptions.Item>

                                <Descriptions.Item
                                  label={t("ViewProfile.currentLiviningIn")}
                                  span={3}
                                >
                                  <div className="userLangugeImg">
                                    <img
                                      alt="example"
                                      src={buddyView.profile?.country.flagUrl}
                                    />
                                    {buddyView?.profile?.country.name}
                                  </div>
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={t("ViewProfile.buddyAbout")}
                                  span={3}
                                >
                                  {buddyView?.profile?.description}
                                </Descriptions.Item>
                              </Descriptions>
                            </div>
                          </div>
                        </div>
                      </Col>
                      {buddyView?.profile?.rating ? (
                        <Col xs={24} lg={12}>
                          <Title level={5}>Rating</Title>
                          <div className="rightCol">
                            <div className="ratedColumn">
                              <div className="rateTitle">
                                Overall Rating {buddyView?.profile?.rating}/5{" "}
                                <Rate
                                  defaultValue={parseInt(
                                    buddyView?.profile?.rating
                                  )}
                                />
                              </div>
                              <Divider />
                              {buddyView?.ratings &&
                              buddyView?.ratings?.length > 0 ? (
                                <>{renderRatings(buddyView?.ratings)}</>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
                    <Button
                      className="sendMsgBtnSp btn-lg"
                      type="primary"
                      onClick={() => setSayhelloVisible(true)}
                      icon={<FiSend />}
                      style={{
                        backgroundColor: `${payload?.theme?.themeColor}`,
                        borderColor: `${payload?.theme?.themeColor}`,
                      }}
                    >
                      {/* {t("ViewProfile.btnSendMessage")} */}Say Hello
                    </Button>

                    {/* <div className="sendMessageBtnStatus">
                      <Button
                        className="sendMessageButtonViePro sendMessageButtonMobile"
                        type="primary"
                        onClick={() => setSayhelloVisible(true)}
                        icon={<FiSend />}
                        size="large"
                      >
                        <div className="sendMessageButtonText">
                          {t("ViewProfile.btnSendMessage")}
                        </div>
                      </Button>

                      <div className="buddyReplyTimeMobile">
                        {t("ViewProfile.buddyReplyTime")}{" "}
                        {buddyView.replyTime.count} {buddyView.replyTime.unit}
                      </div>
                    </div> */}
                  </TabPane>
                </Tabs>
              </Col>

              {/* <Col className="leftColumnBackround">
                <div className="leftColumn">
                  <div className="leftColumnWidget">
                    <Dropdown
                      overlay={menu}
                      trigger={["click"]}
                      placement="bottomRight"
                    >
                      <div
                        className="dropDownProfileButton "
                        style={{ borderColor: `${payload?.theme?.themeColor}` }}
                      >
                        <a
                          className="btnShareProfile"
                          href="Share button"
                          onClick={(e) => e.preventDefault()}
                          style={{
                            color: `${payload?.theme?.themeColor}`,
                            borderColor: `${payload?.theme?.themeColor}`,
                          }}
                        >
                          <FaShareAlt size={20} style={{ paddingRight: "5px" }} />
                          <span className="dropDownIcon">
                            {t("ViewProfile.btnShareDropdown")}
                          </span>

                          <DownOutlined />
                        </a>
                      </div>
                    </Dropdown>
                    <span className="sendMessageButtonViewProfile">
                      <Button
                        className="sendMessageButtonViePro sendMessageButtonMobile"
                        type="primary"
                        onClick={() => setSayhelloVisible(true)}
                        icon={<FiSend />}
                        size="large"
                        style={{
                          backgroundColor: `${payload?.theme?.themeColor}`,
                          borderColor: `${payload?.theme?.themeColor}`,
                        }}
                      >
                        <div className="sendMessageButtonText">
                          {t("ViewProfile.btnSendMessage")}
                        </div>
                      </Button>
                    </span>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
        </Content>
      </Spin>
    </>
  );
}

export default ViewProfile;
